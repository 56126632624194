import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { AppointmentActions, AppointmentFacade, CustomerCaseFacade, DamageFacade, ProductFacade } from "@cg/olb/state";
import { SpinnerFacade } from "@cg/spinner";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AppointmentDetailComponent, AppointmentSearchComponent } from "@cg/appointment-ui";
import { ConfigFacade } from "@cg/config";
import { Paragraph } from "@cg/content-api/typescript-interfaces";
import { ServiceCenter } from "@cg/core/interfaces";
import { AddFormControls } from "@cg/core/types";
import { environment } from "@cg/environments";
import { calendarInfoFilledIcon } from "@cg/icon";
import { GetProductsRequest, gmCountToDamageChipCount, OlbHeadlineComponent } from "@cg/olb/shared";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  Button,
  CustomerCase,
  DrawerComponent,
  JobStatus,
  OverlayFooterComponent,
  OverlayService,
  RequiredService,
  SetAppointmentPayload
} from "@cg/shared";
import { DetailFacade } from "../../../../../+state/detail.facade";
import { CalloutAppointmentComponent } from "../callout-appointment/callout-appointment.component";
@Component({
  selector: "cg-edit-appointment",
  templateUrl: "./edit-appointment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    DrawerComponent,
    OlbHeadlineComponent,
    AppointmentSearchComponent,
    AppointmentDetailComponent,
    OverlayFooterComponent
  ],
  providers: [
    AppointmentFacade,
    ProductFacade,
    DamageFacade,
    CustomerCaseFacade,
    SpinnerFacade,
    ConfigFacade,
    TrackingService
  ]
})
export class EditAppointmentComponent implements OnInit, OnDestroy {
  @Input() public customerCase: CustomerCase;
  public destroyRef = inject(DestroyRef);
  public text: Paragraph;
  public readonly calendarInfoFilledIcon = calendarInfoFilledIcon;
  public appointmentData: AppointmentData;
  public prevAppointmentStartDate: string;
  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;

  public detailFooterButtons: Button[];
  public calloutFooterButtons: Button[];
  private customerCaseId?: string;
  private appointmentId?: string;
  private availableAppointments?: Appointment[];
  private currentAppointmentSub = new Subject<void>();
  public get hasSelectedAppointment(): boolean {
    return !!this.form.value.selectedAppointmentId;
  }

  // eslint-disable-next-line max-params
  public constructor(
    private readonly detailFacade: DetailFacade,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly productFacade: ProductFacade,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly spinnerFacade: SpinnerFacade,
    private readonly configFacade: ConfigFacade,
    private readonly actions$: Actions,
    private readonly translocoService: TranslocoService,
    private readonly overlayService: OverlayService,
    private cdr: ChangeDetectorRef
  ) {
    this.detailFooterButtons = [
      {
        id: "backButton",
        text: this.translocoService.translate("footer.buttons.prev")
      }
    ];

    this.calloutFooterButtons = [
      {
        id: "calloutFooterButton",
        text: this.translocoService.translate("overlayFooter.footerButtons.abort")
      }
    ];
  }

  public ngOnInit(): void {
    environment.features.mobileService = false;
    this.prevAppointmentStartDate = this.customerCase.appointment.availabilityPeriodStart;

    this.initAppointmentList();
  }

  public ngOnDestroy(): void {
    environment.features.mobileService = true;
  }

  public initForm(): void {
    this.form = new FormGroup<AddFormControls<AppointmentSearchForm>>({
      searchServiceCenterInput: new UntypedFormControl("", Validators.required),
      selectedAppointmentId: new UntypedFormControl("", Validators.required)
    });
  }

  // TODO: write test with manual selection
  public setAppointmentData(): void {
    this.currentAppointmentSub.next();
    this.currentAppointmentSub = new Subject<void>();

    this.detailFacade.serviceCenterDetails$
      .pipe(
        filter((serviceCenter: ServiceCenter) => !!serviceCenter),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((serviceCenter: ServiceCenter) => {
        this.spinnerFacade.hideForcedSpinner();
        const requiredService: RequiredService = this.customerCase.damages[0].requiredService as RequiredService;
        const serviceCenterAddress = `${this.customerCase.appointment.city}, ${this.customerCase.appointment.country}`;
        const timerangeBegin =
          requiredService === RequiredService.REPLACE ? this.customerCase.appointment.availabilityPeriodStart : null;

        const car = this.customerCase.car;
        const coordinates = serviceCenter.coordinates;
        const productPayload: GetProductsRequest = {
          customerCaseId: this.customerCase.id,
          productType: requiredService,
          part: this.customerCase.damages[0].compromisedPart,
          ...(car?.vin && { vin: car.vin }),
          ...(car?.brand && { brand: car.brand }),
          ...(car?.model && { model: car.model }),
          ...(car?.vehicleType && { carType: car.vehicleType })
        };

        this.customerCaseFacade.setCustomerCaseId(this.customerCase.id);
        this.customerCaseFacade.setLoaded(true);
        this.damageFacade.setRequiredService(requiredService);

        if (!this.customerCase.shoppingCartEntries?.length) {
          this.productFacade.loadAllProducts(productPayload);
        } else {
          this.productFacade.setSelectedProduct(this.customerCase.shoppingCartEntries);
        }

        this.appointmentFacade.setStatus(this.customerCase.appointment.status);
        this.appointmentFacade.setFormattedAddress(serviceCenterAddress);
        this.appointmentFacade.setSelectedServiceCenterIds([this.customerCase.appointment.location]);
        this.appointmentFacade.setAppointmentLocation({
          city: this.customerCase.appointment.city,
          postalCode: this.customerCase.appointment.postalCode,
          street: this.customerCase.appointment.street
        });
        this.appointmentFacade.saveAutocompleteResult({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
          address: serviceCenterAddress,
          timerangeBegin,
          locality: null
        });
        this.appointmentData = {
          appointmentId$: this.appointmentFacade.appointmentId$,
          currentAppointment$: this.appointmentFacade.currentAppointment$,
          availableAppointments$: this.appointmentFacade.availableAppointments$,
          selectedServiceCenter$: this.appointmentFacade.selectedServiceCenter$,
          selectedServiceCenterIds$: this.appointmentFacade.selectedServiceCenterIds$,
          availableServiceCenters$: this.appointmentFacade.availableServiceCenters$,
          isCalibration$: this.appointmentFacade.isCalibration$,
          requiredService$: of(this.customerCase.damages[0].requiredService as RequiredService),
          formattedAddress$: this.appointmentFacade.formattedAddress$,
          locality$: this.appointmentFacade.locality$,
          position$: this.appointmentFacade.position$,
          mobileServiceAvailable$: this.appointmentFacade.mobileServiceAvailable$,
          hasAdverseBuyAppointments$: this.appointmentFacade.hasAdverseBuyAppointments$,
          confirmed$: this.appointmentFacade.confirmed$,
          appointmentNextLoading$: this.appointmentFacade.appointmentNextLoading$,
          nextLoadingLimitReached$: this.appointmentFacade.nextLoadingLimitReached$,
          isLoading$: this.appointmentFacade.isLoading$,
          customerCaseId$: of(this.customerCase.id),
          damageChipCount$: of(gmCountToDamageChipCount(this.customerCase.damages[0].gmCount)),
          reloadAppointments$: new BehaviorSubject<boolean>(false),
          resumeResponse$: of(null),
          isBookingWithoutAppointmentAllowed: false,
          isScSelectDisabled: requiredService === RequiredService.REPLACE,
          config: this.configFacade.appointmentsConfig$,
          setStatus: (status: JobStatus) => {
            this.appointmentFacade.setStatus(status);
          },
          resetStateForForm: () => {
            this.appointmentFacade.resetStateForForm();
          },
          confirmAppointment: (appointment: SetAppointmentPayload) => {
            this.appointmentFacade.confirmAppointment(appointment);
          },
          setAppointmentId: (appointmentId: string) => {
            this.appointmentFacade.setAppointmentId(appointmentId);
          },
          setSelectedServiceCenterIds: (serviceCenterIds: string[]) => {
            this.appointmentFacade.setSelectedServiceCenterIds(serviceCenterIds);
          },
          clearAndRefetchAppointments: (serviceCenterId: string) => {
            this.appointmentFacade.clearAndRefetchAppointments(serviceCenterId, timerangeBegin);
          },
          setNextLoadingLimitReached: (limitReached: boolean) => {
            this.appointmentFacade.setNextLoadingLimitReached(limitReached);
          },
          setMobileServiceAvailable: (isAvailable: boolean) => {
            this.appointmentFacade.setMobileServiceAvailable(isAvailable);
          },
          getNextAppointments: (date: string) => {
            this.appointmentFacade.getNextAppointments(date);
          },
          saveAutocompleteResult: (lat: number, lng: number, address: string, locality: string) => {
            this.appointmentFacade.saveAutocompleteResult({ lat, lng, address, locality });
          },
          reloadAppointments: () => {
            this.appointmentFacade.reloadAppointments();
          }
        };

        this.cdr.markForCheck();
      });
  }

  public saveForm(): void {
    if (!this.appointmentId) {
      console.error("No appointment id");

      return;
    }

    const selectedAppointment = this.availableAppointments.find(
      (value: Appointment) => value.appointmentId === this.appointmentId
    );
    if (!selectedAppointment) {
      console.error("No selected appointment");

      return;
    }

    const appointmentPayload: Appointment = {
      appointmentId: selectedAppointment.appointmentId,
      customerCaseId: this.customerCaseId,
      availabilityPeriodStart: selectedAppointment.customerAppointmentStart, // has to be updated with customerAppointmentStart because backend does the same...
      availabilityPeriodEnd: selectedAppointment.availabilityPeriodEnd,
      customerAppointmentStart: selectedAppointment.customerAppointmentStart,
      customerAppointmentEnd: selectedAppointment.customerAppointmentEnd,
      serviceCenter: selectedAppointment.serviceCenter,
      street: selectedAppointment.street,
      city: selectedAppointment.city,
      sublocality: selectedAppointment.sublocality,
      postalCode: selectedAppointment.postalCode,
      state: selectedAppointment.state,
      country: selectedAppointment.country,
      fitterId: selectedAppointment.fitterId,
      overallTaskTime: selectedAppointment.overallTaskTime,
      jobStart: selectedAppointment.jobStart,
      jobEnd: selectedAppointment.jobEnd,
      locationType: "INHOUSE",
      status: "FIXED"
    };

    this.detailFacade.updateAppointmentOfCustomer(appointmentPayload, this.prevAppointmentStartDate);
    this.appointmentFacade.setAppointmentId(null);
  }

  public goBackToAppointmentList(): void {
    this.appointmentFacade.setAppointmentId(null);
    this.initAppointmentList();
  }

  public calloutClicked(): void {
    this.overlayService.close();
    this.overlayService.open(CalloutAppointmentComponent);
  }

  private initAppointmentList() {
    this.initForm();
    this.spinnerFacade.forceShowSpinner();
    this.setAppointmentData();

    this.actions$
      .pipe(ofType(AppointmentActions.reloadAppointments), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.appointmentData?.reloadAppointments$.next(true);
      });

    combineLatest([this.appointmentFacade.appointmentId$, this.appointmentFacade.availableAppointments$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([appointmentId, availableAppointments]: [string, Appointment[]]) => {
        this.appointmentId = appointmentId;
        this.availableAppointments = availableAppointments;
        this.customerCaseId = this.customerCase.id;
      });
  }
}
